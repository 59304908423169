module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"null","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ahWeb","path":"/preview","previews":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Augusta HiTech","short_name":"AH","start_url":"/","background_color":"#1F2127","theme_color":"#006FF6","display":"minimal-ui","icon":"src/assets/img/favicon.jpg"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://eb3fe16b4f6d4c26bcc9da5a6f122115@o233697.ingest.sentry.io/5466673","sampleRate":0.7,"environment":"staging"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
